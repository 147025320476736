import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import data from './modules/data';
import search from './modules/search';
import chat from './modules/chat';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    data,
    search,
    chat,
  },
  strict: debug,
});
