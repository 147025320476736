import Vue from 'vue';
import VModal from 'vue-js-modal';
import { Alert, Message, Loading, MessageBox } from 'element-ui';
import VueScrollTo from 'vue-scrollto';

// 3rd party components
// Vue.component('vue-recaptcha', () => import('vue-recaptcha'));
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.use(Loading.directive);
Vue.component('star-rating', () => import('vue-star-rating'));

// Components
Vue.component('cybermarqt-header', () => import('./components/header.vue'));
Vue.component('login-form', () => import('./components/login.vue'));
Vue.component('profile', () => import('./components/profile.vue'));
Vue.component('contact', () => import('./components/contact.vue'));
Vue.component('search-form', () => import('./components/search-form.vue'));
Vue.component('filter-selection', () => import('./components/filter-selection.vue'));
Vue.component('my-company', () => import('./components/my-company.vue'));
Vue.component('view-company', () => import('./components/view-company.vue'));
Vue.component('forgot-password-form', () => import('./components/forgot-password.vue'));
Vue.component('reset-password-form', () => import('./components/reset-password.vue'));
Vue.component('signup-provider-form', () => import('./components/signup-provider.vue'));
Vue.component('signup-customer-form', () => import('./components/signup-customer.vue'));
Vue.component('cyber-news', () => import('./components/cyber-news.vue'));
Vue.component('home-video', () => import('./components/home-video.vue'));
Vue.component('nav-menu', () => import('./components/nav-menu.vue'));
Vue.component('search-result', () => import('./components/search-result.vue'));
Vue.component('claim-popup', () => import('./components/claim-popup.vue'));
Vue.component('country-code-phone-input', () => import('./components/country-code-phone-input.vue'));
Vue.component('search-selection-control', () => import('./components/search-selection-control.vue'));
Vue.component('promotion-brands', () => import('./components/promotion-brands.vue'));
Vue.component('statistic', () => import('./components/statistic.vue'));
Vue.component('rating-request-form', () => import('./components/rating-request-form.vue'));
Vue.component('company-selection-control', () => import('./components/company-selection-control.vue'));

Vue.component('rfps-index', () => import('./components/rfps-index.vue'));
Vue.component('rfps-bids', () => import('./components/rfps-bids.vue'));
Vue.component('rfps-edit', () => import('./components/rfps-edit.vue'));
Vue.component('rfps-search-form', () => import('./components/rfps-search-form.vue'));
Vue.component('rfps-search-selections', () => import('./components/rfps-search-selections.vue'));
Vue.component('rfps-search-selection', () => import('./components/rfps-search-selection.vue'));
Vue.component('rfps-search-result', () => import('./components/rfps-search-result.vue'));
Vue.component('rfps-search-result-item', () => import('./components/rfps-search-result-item.vue'));
Vue.component('rfps-view', () => import('./components/rfps-view.vue'));

// Chat
Vue.component('chat-view', () => import('./components/chat/chat-view.vue'));

Vue.component('bid-index', () => import('./components/bid-index.vue'));
// Element UI
Vue.component('el-alert', Alert);

// Async components
Vue.component('interactive-map', () => import('./components/interactive-map.vue'));

// Plugins
Vue.use(VModal, { dialog: true });
Vue.use(VueScrollTo);
