import m from './types/mutations';

export default {
  // User
  [m.RECEIVED_CURRENT_USER_ID](state, id) {
    state.entities.users.currentUserId = id;
  },

  [m.RECEIVED_TEAM_ID](state, teamId) {
    state.entities.teams.currentTeamId = teamId;
  },

  [m.RECEIVED_PROFILES](state, users) {
    const profiles = users.reduce((p, user) => ({ ...p, [user.id]: user }), {});
    state.entities.users.profiles = profiles;
  },

  // Channels
  [m.RECEIVED_CHANNEL](state, channel) {
    state.entities.channels.channels[channel.id] = channel;
    state.entities.channels.currentChannelId = channel.id;
  },

  [m.RECEIVED_CHANNEL_INFO](state, { channels, myMembers }) {
    state.entities.channels.channels = channels.reduce((c, channel) => ({ ...c, [channel.id]: channel }), {});
    state.entities.channels.myMembers = myMembers.reduce(
      (c, myMember) => ({ ...c, [myMember.channel_id]: myMember }),
      {}
    );
  },

  [m.RESET_CHANNEL](state) {
    state.entities.channels.currentChannelId = null;
    state.entities.posts.posts = {};
    state.entities.posts.order = [];
    state.entities.posts.pendingPostIds = [];
  },

  // Websocket

  // Posts
  [m.RECEIVED_POSTS](state, data) {
    state.entities.posts.posts = data.posts;
    state.entities.posts.order = data.order;
  },

  [m.RECEIVED_POST](state, post) {
    if (state.entities.channels.currentChannelId === post.channel_id) {
      const { posts, order, pendingPostIds } = state.entities.posts;

      posts[post.id] = post;
      if (!order.includes(post.id)) {
        order.unshift(post.id);
      }

      // Remove the pending post from the state (posts, order, and pendingPostIds)
      if (post.pending_post_id) {
        Reflect.deleteProperty(posts, post.pending_post_id);

        const index = pendingPostIds.indexOf(post.pending_post_id);
        if (index !== -1) {
          pendingPostIds.splice(index, 1);
        }

        const orderIndex = order.indexOf(post.pending_post_id);
        if (orderIndex !== -1) {
          order.splice(orderIndex, 1);
        }
      }

      state.entities.posts.posts = { ...posts };
      state.entities.posts.order = [...order];
      state.entities.posts.pendingPostIds = [...pendingPostIds];
    }

    if (
      state.entities.users.currentUserId !== post.user_id &&
      state.entities.channels.currentChannelId !== post.channel_id
    ) {
      if (state.entities.channels.channels[post.channel_id] != null) {
        state.entities.channels.channels[post.channel_id].total_msg_count += 1;
      }
    }
  },

  [m.RECEIVED_NEW_POST](state, post) {
    const { posts, order, pendingPostIds } = state.entities.posts;
    state.entities.posts.posts = { ...posts, [post.id]: post };
    state.entities.posts.order = [post.id, ...order];
    state.entities.posts.pendingPostIds = [...pendingPostIds, post.id];
  },
};
