// initial state
const initialState = {
  user: null,
};

// getters
const getters = {
  user: state => state.user,
};

// actions
const actions = {};

// mutations
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
};

export default {
  getters,
  actions,
  mutations,
  state: initialState,
  namespaced: true,
};
