const events = {
  SEARCH_TRIGGERED: null,
  // resize window event
  WINDOW_RESIZED: null,

  // href means protocol https:, path and file //domain.com/search.php, and query a=val1&b=val2
  // if the query serves as the source of truth, its change will trigger UI controls to update.
  // when the user navigates to this page (be it go or back or forward), many controls need to be updated
  PAGE_SHOW_TRIGGERS_FORM_UPDATE: null,
  PAGE_SHOW_TRIGGERS_SELECTION_UPDATE: null,
  PAGE_SHOW_TRIGGERS_TAG_UPDATE_SEARCH: null,
  SELECTION_CHANGE_TRIGGERS_TAG_UPDATE_SEARCH: null,
  TAG_CLOSE_TRIGGERS_SELECTION_UPDATE: null,
  TERMS_CHANGE_TRIGGERS_SEARCH: null,
  MY_COMPANIES: null,
  ID_CLICKED: null,
  BID_STATUS_CHANGED: null,

  /**
   * Chat related events
   */
  CHAT_POST_LIST_SCROLL_CHANGE: null,
  CHAT_POST_HEADER_CLOSE: null,
};

Object.keys(events).forEach(key => {
  events[key] = key;
});

export default events;
