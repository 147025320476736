// initial state
const initialState = {
  isSearching: false,
  filters: {
    targets: [],
    categories: [],
    prices: [],
    services: [],
    sectors: [],
    cities: [],
    certificates: [],
    name: null,
    country: null,
  },
  filterSelections: {
    cities: [],
    targets: [],
    categories: [],
    services: [],
    sectors: [],
  },
};

// getters
const getters = {
  isSearching: state => state.isSearching,
  filters: state => state.filters,
  filterSelections: state => state.filterSelections,
  searchingCities: state => state.filterSelections.cities,
  searchingTargets: state => state.filterSelections.targets,
  searchingServices: state => state.filterSelections.services,
  searchingSectors: state => state.filterSelections.sectors,
  searchingCategories: state => state.filterSelections.categories,
};

// actions
const actions = {};

// mutations
const mutations = {
  setIsSearching(state, searching) {
    state.isSearching = searching;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setTargets(state, targets) {
    state.filters.targets = targets;
  },
  setCategories(state, categories) {
    state.filters.categories = categories;
  },
  setPrices(state, prices) {
    state.filters.prices = prices;
  },
  setServices(state, services) {
    state.filters.services = services;
  },
  setSectors(state, sectors) {
    state.filters.sectors = sectors;
  },
  setCities(state, cities) {
    state.filters.cities = cities;
  },
  setCertificates(state, certificates) {
    state.filters.certificates = certificates;
  },
  setFilterSelections(state, filters) {
    state.filterSelections = filters;
  },
  setCitiesSelections(state, cities) {
    state.filterSelections.cities = cities;
  },
  setTargetsSelections(state, targets) {
    state.filterSelections.targets = targets;
  },
  setServicesSelections(state, services) {
    state.filterSelections.services = services;
  },
  setSectorsSelections(state, sectors) {
    state.filterSelections.sectors = sectors;
  },
  setCategoriesSelections(state, categories) {
    state.filterSelections.categories = categories;
  },
};

export default {
  getters,
  actions,
  mutations,
  state: initialState,
  namespaced: true,
};
