const types = {
  initialize: null,
  resetChannel: null,

  // Channels
  fetchChannel: null,
  fetchChannelMembers: null,

  // Posts
  fetchPosts: null,
  createPost: null,
  handleNewPostEvent: null,
};

Object.keys(types).forEach(key => {
  types[key] = key;
});

export default types;
