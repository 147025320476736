export default {
  chatState: state => state,
  currentChannel: state => state.entities.channels.channels[state.entities.channels.currentChannelId],
  posts: state => {
    // const { currentChannelId } = state.entities.channels;
    // const { postsInChannel } = state.entities.posts;

    // return postsInChannel[currentChannelId]
    //   .map(id => state.entities.posts.posts[id])
    //   .filter(post => post.type === '')
    //   .reverse();

    return state.entities.posts.order
      .map(id => state.entities.posts.posts[id])
      .filter(post => post.type === '')
      .reverse();
  },
  // currentUser: state => state.entities.users.profiles[state.entities.users.currentUserId],
  currentUserId: state => state.entities.users.currentUserId,
  userById: state => id => state.entities.users.profiles[id],

  unreadMessageByChannel: state => id => {
    if (state.entities.channels.channels[id] != null && state.entities.channels.myMembers[id] != null) {
      const totalMsg = state.entities.channels.channels[id].total_msg_count;
      const myMsg = state.entities.channels.myMembers[id].msg_count;
      return totalMsg - myMsg;
    }
    return 0;
  },
};
