export default {
  methods: {
    parseError(er) {
      let errors = null;

      if (er.errors) {
        errors = Object.values(er.errors);
      }

      return errors.map(error => error[0]);
    },
  },
};
