import Vue from 'vue';
import parseValidationError from './mixins/parseValidationError';
import store from './store';
import Events from './events';

require('@babel/polyfill');

require('./bootstrap');
require('./components');
require('./directives');

/**
 * This is our event bus, which is used for event dispatching. The base is that we create an empty
 * Vue instance. First we fire the event by: "this.$eventHub.$emit('eventName', 'data')"
 * and later we listen for it by: "this.$eventHub.$on('eventName', this.newComment)"
 *
 *
 * (which is defined in the created() function of the vue componentr (or root instance), after catching the
 * event, passes the data to the defined funciton. In this example case it's newComment() but notice that
 * it doesn't require to be actually written as argumans! ) Happy eventing in your awesome components.
 */
Vue.prototype.$eventHub = new Vue();

// eslint-disable-next-line no-new
new Vue({
  el: '#app',

  store,

  mixins: [parseValidationError],

  data() {
    return {
      isShowScrollUpButton: false,
    };
  },

  created() {
    // controller pulls data from database and puts them into php
    // blade pours data from php into DOM (see views/front-end/head.blade.php)
    // app.js gets the data from DOM into vue (js) (here)
    this.$store.commit('auth/setUser', window.auth.user);
    this.$store.commit('data/setData', window.data);

    // DOM events are linked to vue functions (here)
    // vue functions are then linked to vue events
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleScroll(event) {
      const box = event.target.documentElement;
      let { scrollHeight, scrollTop } = box; // eslint-disable-line
      if (scrollTop === 0) {
        scrollTop = event.target.scrollingElement.scrollTop || 0;
      }
      const clientHeight = document.documentElement.clientHeight || 0;
      let checkPoint = scrollHeight - clientHeight;
      checkPoint -= checkPoint / 2;
      // turn on and off the button "UP" (at right bottom)
      if (scrollTop > checkPoint) {
        this.isShowScrollUpButton = true;
      } else {
        this.isShowScrollUpButton = false;
      }
    },

    // DOM events are linked to vue functions
    // vue functions are then linked to vue events (here)
    handleResize() {
      // no data emitted
      // responsive design = alert the world that the window has been resized.
      this.$eventHub.$emit(Events.WINDOW_RESIZED);
    },
  },
});
