import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  entities: {
    general: {
      appState: false,
      credentials: {},
      config: {},
      dataRetentionPolicy: {},
      deviceToken: '',
      license: {},
      serverVersion: '',
      timezones: [],
    },
    users: {
      rfpUserId: '',
      bidUserId: '',
      currentUserId: '',
      profiles: {},
      statuses: {},
      stats: {},
    },
    teams: {
      currentTeamId: '',
      teams: {},
    },
    channels: {
      currentChannelId: '',
      channels: {},
      stats: {},
      totalCount: 0,
      myMembers: {},
    },
    posts: {
      expandedURLs: {},
      order: [],
      posts: {},
      postsInChannel: {},
      pendingPostIds: [],
      selectedPostId: '',
      currentFocusedPostId: '',
      messagesHistory: {
        messages: [],
        index: {
          post: -1,
          comment: -1,
        },
      },
    },
    typing: {},
  },
  requests: {
    channels: {
      getAllChannels: {
        status: 'not_started',
        error: null,
      },
      getChannels: {
        status: 'not_started',
        error: null,
      },
      myChannels: {
        status: 'not_started',
        error: null,
      },
      createChannel: {
        status: 'not_started',
        error: null,
      },
      updateChannel: {
        status: 'not_started',
        error: null,
      },
    },
    general: {
      server: {
        status: 'not_started',
        error: null,
      },
      config: {
        status: 'not_started',
        error: null,
      },
      websocket: {
        status: 'not_started',
        error: null,
      },
    },
    posts: {
      createPost: {
        status: 'not_started',
        error: null,
      },
      editPost: {
        status: 'not_started',
        error: null,
      },
      getPostThread: {
        status: 'not_started',
        error: null,
      },
    },
  },
  websocket: {
    connected: false,
    lastConnectAt: 0,
    lastDisconnectAt: 0,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
