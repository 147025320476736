// window.moment = require('moment-timezone');
// window.moment.tz.setDefault('UTC');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = require('axios');

window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.Laravel.csrfToken;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common.Accept = 'application/vnd.cyber-marqt.v1+json';

window.axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // TODO: Handle 401 error
    }

    return Promise.reject(error);
  }
);
