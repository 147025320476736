import { Client4 } from 'mattermost-redux/client';
import WebSocketClient from 'mattermost-redux/client/websocket_client';
import axios from 'axios';
import m from './types/mutations';
import a from './types/actions';

// Set the dispatch globaly, so that the websocket callback can use it to dispatch actions
let doDispatch;

function handleEvent(msg) {
  switch (msg.event) {
    case 'posted': {
      doDispatch(a.handleNewPostEvent, msg);
      break;
    }
    default:
      break;
  }
}

export default {
  async [a.initialize]({ dispatch, commit }) {
    doDispatch = dispatch;

    const res = await axios.post(`/api/me/chat`);
    const { token, id, serverUrl, websocketUrl, teamId } = res.data;
    // Init http client
    Client4.setUrl(serverUrl);
    Client4.setToken(token);
    Client4.setUserId(id);

    // Init websocket client
    WebSocketClient.close();
    WebSocketClient.setEventCallback(handleEvent);
    WebSocketClient.initialize(token, { connectionUrl: websocketUrl });

    commit(m.RECEIVED_CURRENT_USER_ID, id);
    commit(m.RECEIVED_TEAM_ID, teamId);
    commit(m.RECEIVED_CHANNEL_INFO, {
      myMembers: await Client4.getMyChannelMembers(teamId),
      channels: await Client4.getMyChannels(teamId),
    });
  },

  async [a.resetChannel]({ commit }) {
    commit(m.RESET_CHANNEL);
  },

  // Channels
  async [a.fetchChannel]({ commit, state }, { bidId }) {
    // Start fetching the channel & users
    commit(m.RESET_CHANNEL);

    const res = await axios.post(`/api/me/bid/${bidId}/chat-channel`);
    const channelId = res.data.channel_id;
    await Client4.viewMyChannel(channelId);

    const teamId = state.entities.teams.currentTeamId;

    commit(m.RECEIVED_CHANNEL, await Client4.getChannel(channelId));
    commit(m.RECEIVED_POSTS, await Client4.getPosts(channelId));
    commit(m.RECEIVED_CHANNEL_INFO, {
      myMembers: await Client4.getMyChannelMembers(teamId),
      channels: await Client4.getMyChannels(teamId),
    });

    // call view api
  },

  async [a.createPost]({ commit, getters, state }, { message }) {
    const { currentUserId } = state.entities.users;
    const timestamp = Date.now();
    const pendingPostId = `${currentUserId}:${timestamp}`;

    const pendingPost = {
      channel_id: getters.currentChannel.id,
      message,
      user_id: currentUserId,
      pending_post_id: pendingPostId,
      create_at: timestamp,
      update_at: timestamp,
    };

    commit(m.RECEIVED_NEW_POST, { ...pendingPost, id: pendingPostId });

    const post = await Client4.createPost(pendingPost);

    commit(m.RECEIVED_POST, {
      ...pendingPost,
      ...post,
    });
  },

  async [a.handleNewPostEvent]({ commit, getters }, msg) {
    const post = JSON.parse(msg.data.post);
    commit(m.RECEIVED_POST, post);
    await Client4.viewMyChannel(getters.currentChannel.id);
  },
};
