const types = {
  // Channels
  RESET_CHANNEL: null,
  RECEIVED_CHANNEL: null,
  RECEIVED_CHANNEL_INFO: null,
  RECEIVED_CHANNEL_MEMBERS: null,

  // Team
  RECEIVED_TEAM_ID: null,

  // Websocket

  // User
  RECEIVED_CURRENT_USER_ID: null,
  RECEIVED_PROFILES: null,

  // Posts
  RECEIVED_POST: null,
  RECEIVED_NEW_POST: null,

  RECEIVED_POSTS: null,
  RECEIVED_POSTS_AFTER: null,
  RECEIVED_POSTS_BEFORE: null,
  RECEIVED_POSTS_IN_CHANNEL: null,
  RECEIVED_POSTS_SINCE: null,
};

Object.keys(types).forEach(key => {
  types[key] = key;
});

export default types;
