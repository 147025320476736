// initial state
const initialState = {
  categories: [],
  sizes: [],
  prices: [],
  certificates: [],
  services: [],
  languages: [],
  targets: [],
  interests: [],
  businessValues: [],
  sectors: [],
  cities: [],
  companies: [],
  countries: [],
};

// getters
const getters = {
  categories: state => state.categories,
  sizes: state => state.sizes,
  prices: state => state.prices,
  certificates: state => state.certificates,
  services: state => state.services,
  languages: state => state.languages,
  targets: state => state.targets,
  interests: state => state.interests,
  businessValues: state => state.businessValues,
  sectors: state => state.sectors,
  cities: state => state.cities,
  companies: state => state.companies,
  countries: state => state.countries,
};

// actions
const actions = {};

// mutations
const mutations = {
  setData(state, data = {}) {
    state.categories = data.categories;
    state.sizes = data.sizes;
    state.prices = data.prices;
    state.certificates = data.certificates;
    state.services = data.services;
    state.languages = data.languages;
    state.targets = data.targets;
    state.interests = data.interests;
    state.businessValues = data.businessValues;
    state.sectors = data.sectors;
    state.cities = data.cities;
    state.companies = data.companies;
    state.countries = data.countries;
  },
};

export default {
  getters,
  actions,
  mutations,
  state: initialState,
  namespaced: true,
};
